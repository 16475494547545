import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Howl, Howler } from 'howler';
import { User } from 'src/app/entities/user.model';
import { WebsocketService } from '../../../components/messaging/service/websocket.service';
import { environment } from '../../../../environments/environment';
import { VideoService } from '../video.service';
import { TranslateModule } from '@ngx-translate/core';
import { ProfilePictureComponent } from '../../components/profile-picture/profile-picture.component';
import { MonitoringService } from '../../services/monitoring/monitoring.service';

enum CallStatus {
  idle,
  callIncoming,
  joining,
}

@Component({
    selector: 'app-call',
    templateUrl: './call.component.html',
    styleUrls: ['./call.component.scss'],
    imports: [TranslateModule, ProfilePictureComponent]
})
export class CallComponent implements OnInit, OnDestroy {
  @Input() callId: string;
  @Input() currentUser: User;
  @Input() otherUser: User;

  public callStatus: CallStatus = CallStatus.idle;
  public therapistProfileId: number;
  private userChannel: any; // Define the appropriate type for your channel
  private sound: Howl;
  private closeComponent: () => void;

  constructor(
    private socketService: WebsocketService,
    private videoService: VideoService
  ) {}

  ngOnInit() {
    if (this.currentUser && this.callId && this.otherUser) {
      this.therapistProfileId =
        this.otherUser.profile_id || this.otherUser.profile.id;
      this.callStatus = CallStatus.callIncoming;
      this.userChannel = this.socketService.getUserChannel(this.currentUser);
      this.userChannel.received().subscribe(this.handleSignal.bind(this));
      this.startRinging();
    } else {
      MonitoringService.captureMessage(
        'CallComponent: callSlug or otherUser is missing'
      );
      this.closeComponent();
    }
  }

  ngOnDestroy() {
    this.stopRinging();
    if (this.callStatus === CallStatus.callIncoming) {
      this.sendCallDeclined();
    }
  }

  acceptIncomingCall() {
    this.stopRinging();
    this.callStatus = CallStatus.joining;

    this.userChannel.send({
      type: 'videocall_accepted',
      from: this.currentUser.id,
      to: this.otherUser.id,
      provider: 'getstream',
    });
    this.videoService.navigateToGetstreamVideoCall(
      this.videoService.generateJoinAdHocCallSlug(
        this.therapistProfileId,
        this.currentUser.id,
        this.callId
      )
    );
    this.stopRingingAndClose();
  }

  declineIncomingCall() {
    this.stopRinging();
    this.sendCallDeclined();
    this.closeComponent();
  }

  private sendCallDeclined() {
    this.userChannel.send({
      type: 'videocall_declined',
      from: this.currentUser.id,
      to: this.otherUser.id,
    });
  }

  private handleSignal(signal) {
    if (!this.currentUser || signal.to !== this.currentUser.id) {
      return;
    }
    if (signal.type === 'videocall_accepted') {
      this.videoService.navigateToGetstreamVideoCall(this.callId);
      this.stopRingingAndClose();
    } else if (
      signal.type === 'videocall_declined' &&
      signal.from !== this.currentUser.id
    ) {
      this.callStatus = CallStatus.idle;
      this.stopRinging();
    }
  }

  private startRinging() {
    this.sound = new Howl({
      src: [environment.baseHref + 'assets/ringtone.mp3'],
      loop: true,
      volume: 0.5,
    });
    this.sound.play();
  }

  private stopRinging() {
    if (this.sound) {
      this.sound.stop();
    }
    Howler.unload();
  }

  private stopRingingAndClose() {
    this.stopRinging();
    this.closeComponent();
  }

  setCloseComponentFn(fn: () => void) {
    this.closeComponent = fn;
  }
}
