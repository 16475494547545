import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { User } from '../../../entities/user.model';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  constructor(private route: ActivatedRoute) {}

  isUsingGetStreamChat(user: User): boolean {
    const legacyChat = this.route.snapshot.queryParamMap.get('legacy_chat');
    return legacyChat !== '1';
  }

  isAllowingProfileLauncher(user: User): boolean {
    return true;
    // return (
    //   user.is_feature_tester ||
    //   environment.name === 'staging' ||
    //   environment.name === 'development'
    // );
  }
}
