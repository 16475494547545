import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgIf, NgOptimizedImage, NgStyle } from '@angular/common';
import { MatchedByIcComponent } from '../badges/matched-by-ic/matched-by-ic.component';
import { MatIcon } from '@angular/material/icon';
import { AngularSvgIconModule, SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'app-avatar-image',
  templateUrl: './avatar-image.component.html',
  styleUrls: ['./avatar-image.component.scss'],
  imports: [
    NgIf,
    NgOptimizedImage,
    NgStyle,
    MatchedByIcComponent,
    MatIcon,
    SvgIconComponent,
    AngularSvgIconModule,
  ],
})
export class AvatarImageComponent implements OnChanges {
  @Input() src: string;
  @Input() alt: string = 'Profile Picture';
  @Input() showMatchedBadge: boolean = false;
  @Input() showNotification: boolean = false;
  @Input() loading: boolean = false;

  // Add this property to track image load failures
  imageLoadError: boolean = false;

  // Add this method to handle image load errors
  onImageError() {
    this.imageLoadError = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['src']) {
      this.imageLoadError = false;
    }
  }
}
