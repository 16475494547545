import { Channel } from 'angular2-actioncable';
import { Component, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../../entities/user.model';
import { GenericHttpService } from './Generic HTTP/generic-http.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie';
import { WebsocketService } from 'src/app/components/messaging/service/websocket.service';
import { Howler } from 'howler';
import { TranslateService } from '@ngx-translate/core';
import { VideoService } from '../video/video.service';
import { AmplitudeAnalyticsService } from './analytics/amplitude-analytics.service';
import { MonitoringService } from './monitoring/monitoring.service';
import { AuthStatesManager } from '../../auth/service/auth.states.manager';
import { map } from 'rxjs/operators';
import { InvoiceAttentionBanner } from './invoiceAttentionBanner';
import { LocaleService } from 'src/app/office/shared/service/locale.service';

declare global {
  interface Window {
    dataLayer: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  userChannel: Channel;

  constructor(
    private http: GenericHttpService,
    private cookieService: CookieService,
    private socketService: WebsocketService,
    private translateService: TranslateService,
    private videoService: VideoService,
    private analyticsService: AmplitudeAnalyticsService,
    private authStatesManager: AuthStatesManager,
    private localeService: LocaleService
  ) {
    window.dataLayer = window.dataLayer || {};
    Howler.unload();
  }

  private currentComponentSource = new BehaviorSubject<Component>(null);
  currentComponent = this.currentComponentSource.asObservable();

  private currentUserSource = new BehaviorSubject<User>(null);
  currentUser = this.currentUserSource.asObservable();

  private showStepsComponentSource = new BehaviorSubject<boolean>(null);
  showStepsComponent = this.showStepsComponentSource.asObservable();

  private showInviteThreapistsComponentSource = new BehaviorSubject<boolean>(
    null
  );
  showInviteThreapistsComponent =
    this.showInviteThreapistsComponentSource.asObservable();

  private inviteClientModalSource = new BehaviorSubject<any>(null);
  inviteClientModal = this.inviteClientModalSource.asObservable();

  private payableInvoiceSource = new BehaviorSubject<any>(null);
  payableInvoice = this.payableInvoiceSource.asObservable();

  private paidInvoiceSource = new BehaviorSubject<any>(null);
  paidInvoice = this.paidInvoiceSource.asObservable();

  private messageNotificationsSource = new BehaviorSubject<number>(null);
  messageNotifications = this.messageNotificationsSource.asObservable();

  private stepsSource = new BehaviorSubject<number>(1);
  steps = this.stepsSource.asObservable();

  private keyStatic: any;
  private keySource = new BehaviorSubject<any>(null);
  public keyObs = this.keySource.asObservable();

  public setKey = (key) => {
    this.keyStatic = key;
    this.keySource.next(key);
  };

  public getKey = (): any => this.keyStatic;

  setSteps(step: number) {
    this.stepsSource.next(step);
  }

  setMessageNotifications(notifications) {
    this.messageNotificationsSource.next(notifications);
  }

  setInviteClientModal(state) {
    this.inviteClientModalSource.next(state);
  }

  setCurrentUser(user: User) {
    if (user) {
      MonitoringService.setUser(user);
      this.analyticsService.setUser(user);
      this.setAvatar(user);
      this.localeService.setLocale(user.locale, user.profile.timezone);
      this.socketService.setupConnection(user.websocket_token);
      this.userChannel = this.socketService.getUserChannel(user);
      this.userChannel.received().subscribe((signal) => {
        if (signal.type === 'videocall') {
          this.videoService.callIncoming(signal.room, user, signal.from);
        }
      });
      this.pingStatus();
      setInterval(() => {
        this.pingStatus();
      }, 10000);
    } else {
      this.authStatesManager.clearLocalState();
      MonitoringService.clearUser();
      this.analyticsService.clearUser();
      this.removeAvatar();
      this.cookieService.remove('ic_hide_invoice_onboarding_banner');
      this.socketService.disconnect();
    }
    this.currentUserSource.next(user);
  }

  updateUser(user: User) {
    this.currentUserSource.next(user);
  }

  pingStatus() {
    if (this.userChannel) {
      this.userChannel.send({ type: 'onlinestatus' });
    }
  }

  setAvatar(user: User) {
    if (user && user.profile) {
      let url = environment.apiBase + '/imgprxy/' + user.profile.id + '/60/60';
      let d = new Date();
      d.setDate(d.getDate() + 30);
      this.cookieService.put('ic_avatar', url, {
        domain: environment.domain,
        storeUnencoded: true,
      });
      this.cookieService.put('ic_usertype', user.type, {
        domain: environment.domain,
      });
    }
  }

  removeAvatar() {
    this.cookieService.remove('ic_avatar', {
      domain: environment.domain,
      storeUnencoded: true,
    });
    this.cookieService.remove('ic_usertype', { domain: environment.domain });
  }

  isFirstLogin() {
    return this.http.get('/firstlogin');
  }

  track(event) {
    window.dataLayer.push({
      event: event,
    });
  }

  trackEvent(category: string, action: string, label?: string, value?: string) {
    label = label == undefined ? '' : label;
    value = value == undefined ? '' : value;

    window.dataLayer.push({
      event: 'trackEvent',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
    });
  }

  trackAsync(event) {
    return new Promise<void>((resolve, reject) => {
      if (Object.keys(window.dataLayer).length === 0) {
        // Development environment where GTM is not active
        resolve();
      } else {
        window.dataLayer.push({ event, eventCallback: resolve });
      }
    });
  }

  setPayableInvoice(id: any) {
    this.payableInvoiceSource.next(id);
  }
  removePayableInvoice() {
    this.setPayableInvoice(null);
  }
  setPaidInvoice(id: any) {
    this.paidInvoiceSource.next(id);
  }
  removePaidInvoice() {
    this.setPaidInvoice(null);
  }

  currentInvoiceAttentionBanner() {
    return this.currentUser.pipe(
      map((user) => {
        if (
          user &&
          user.profile.stripe_connect_active &&
          user.profile.stripe_connect_restriction_deadline
        ) {
          return InvoiceAttentionBanner.AccountWillBeRestrictedSoon;
        }

        if (
          user &&
          this.cookieService.get('ic_hide_invoice_onboarding_banner') !==
            'true' &&
          (!user.profile.stripe_connect_id ||
            user.profile.stripe_connect_has_currently_due_requirements) &&
          !environment.production // COM-474, remove this line as part of that
        ) {
          return InvoiceAttentionBanner.AccountIncomplete;
        }
        return InvoiceAttentionBanner.Hidden;
      })
    );
  }

  hideInvoiceOnboardingBanner() {
    this.cookieService.put('ic_hide_invoice_onboarding_banner', 'true');
  }
}
