export const defaults = {
  retain_coupon: 'P-Ux65-DP',
  stripe_available_countries: [
    'AU', // Australia
    'AT', // Austria
    'BE', // Belgium
    'BG', // Bulgaria
    'CA', // Canada
    'HR', // Croatia
    'CY', // Cyprus
    'CZ', // Czech Republic/Czechia
    'DK', // Denmark
    'EE', // Estonia
    'FI', // Finland
    'FR', // France
    'DE', // Germany
    'GI', // Gibraltar
    'GR', // Greece
    'HK', // Hong Kong
    'HU', // Hungary
    'IE', // Ireland
    'IT', // Italy
    'JP', // Japan
    'LV', // Latvia
    'LI', // Liechtenstein
    'LT', // Lithuania
    'LU', // Luxembourg
    'MY', // Malaysia
    'MT', // Malta
    'MX', // Mexico
    'NL', // Netherlands
    'NZ', // New Zealand
    'NO', // Norway
    'PL', // Poland
    'PT', // Portugal
    'RO', // Romania
    'SG', // Singapore
    'SK', // Slovakia
    'SI', // Slovenia
    'ES', // Spain
    'SE', // Sweden
    'CH', // Switzerland
    'TH', // Thailand
    'AE', // United Arab Emirates
    'GB', // United Kingdom
    'US', // United States
  ],
};
