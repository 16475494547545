import { Component, Input, OnInit } from '@angular/core';
import { Profile } from 'src/app/entities/profile.model';
import { environment } from 'src/environments/environment';
import { NgIf } from '@angular/common';
import { AvatarImageComponent } from '../avatar-image/avatar-image.component';
import {
  getProfileImageUrl,
  ImageVariant,
} from '../../helpers/profile-image-helper';
@Component({
    selector: 'app-profile-picture',
    templateUrl: './profile-picture.component.html',
    styleUrls: ['./profile-picture.component.scss'],
    imports: [NgIf, AvatarImageComponent]
})
export class ProfilePictureComponent implements OnInit {
  @Input() profile_id: number;
  @Input() variant: ImageVariant = 'thumbnail';
  @Input() showMatchedBadge: boolean = false;
  @Input() notifications: number;

  public base: string = environment.apiBase;

  constructor() {}

  ngOnInit() {}

  public getProfileImageUrl(): string {
    return getProfileImageUrl(this.profile_id, this.variant);
  }
}
