import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';

// Initialize required plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime); // needed for fromNow()

@Pipe({
  name: 'timeAgo',
  pure: false,
  standalone: true,
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: Date | dayjs.Dayjs | string | number): string {
    if (!value) {
      return '';
    }

    // If already a Dayjs object
    if (dayjs.isDayjs(value)) {
      return value.tz().fromNow();
    }

    // For strings, check if UTC format
    if (
      typeof value === 'string' &&
      (value.endsWith('Z') || /[+-]\d{2}:?\d{2}$/.test(value))
    ) {
      return dayjs.utc(value).tz().fromNow();
    }

    // For timestamps (numbers), assume they're UTC milliseconds
    if (typeof value === 'number') {
      return dayjs.utc(value).tz().fromNow();
    }

    // For everything else (local dates or non-UTC strings)
    return dayjs.tz(value).fromNow();
  }
}
