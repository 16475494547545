import { Injectable } from '@angular/core';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Invoice, InvoiceExtended } from 'src/app/entities/invoice.model';
import { InvoiceClient } from 'src/app/entities/invoice-client.model';
import { INVOICE_ACTIONS } from '../models/invoice_actions.enum';
import { map, tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OfficeService {
  constructor(private http: GenericHttpService) {}

  private stripeAccountSource: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  private stripeConnectClientSecretSource: BehaviorSubject<
    string | null | undefined
  > = new BehaviorSubject<string | null | undefined>(undefined);
  public stripeConnectAccount: Observable<any> =
    this.stripeAccountSource.asObservable();

  public stripeConnectClientSecret: Observable<any> =
    this.stripeConnectClientSecretSource.asObservable();

  // Account Creation
  setStripeConnectAccount = (stripeConnectAccount: any) =>
    this.stripeAccountSource.next(stripeConnectAccount);
  loadStripeConnectAccount = (): any => {
    return this.http
      .get('/office/accounts')
      .subscribe(this.setStripeConnectAccount);
  };
  updateStripeConnectAccount = (stripeConnectAccount: any) => {
    return this.http.put('/office/accounts', stripeConnectAccount);
  };
  createStripeConnectAccount = (accountType) => {
    return this.http.post('/office/accounts', { account_type: accountType });
  };
  deleteStripeConnectAccount = () => {
    return this.http.delete('/office/accounts');
  };

  getCurrentlyDueStripeAccountLinks(isOnboarding: boolean): Observable<any> {
    return this.http.get(
      isOnboarding
        ? '/office/account_links/onboarding/currently-due'
        : '/office/account_links/update/currently-due'
    );
  }

  getEventuallyDueStripeAccountLinks(isOnboarding: boolean): Observable<any> {
    return this.http.get(
      isOnboarding
        ? '/office/account_links/onboarding/eventually-due'
        : '/office/account_links/update/eventually-due'
    );
  }

  // Invoices
  private isCreateInvoiceModalVisibleSource: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isCreateInvoiceModalVisible: Observable<any> =
    this.isCreateInvoiceModalVisibleSource.asObservable();

  toggleCreateInvoiceModal = (show: boolean) => {
    this.isCreateInvoiceModalVisibleSource.next(show);
  };

  updateInvoice = (invoice: InvoiceExtended): Observable<any> =>
    this.http.put('/office/invoices/' + invoice.id, invoice);
  cancelInvoice = (invoice: InvoiceExtended): Observable<any> =>
    this.http.post('/office/invoices/' + invoice.id + '/cancel', {});
  refundInvoice = (invoice: InvoiceExtended): Observable<any> =>
    this.http.post('/office/invoices/' + invoice.id + '/refund', {});
  remindInvoice = (invoice: Invoice): Observable<any> =>
    this.http.post('/office/invoices/' + invoice.id + '/remind', {});
  markInvoiceAsPaid = (invoice: InvoiceExtended): Observable<any> =>
    this.http.post('/office/invoices/' + invoice.id + '/paid', {});
  createInvoice = (invoice: InvoiceExtended): Observable<any> =>
    this.http.post('/office/invoices/', { invoice });
  getInvoice = (id: number): Observable<any> =>
    this.http.get('/office/invoices/' + id);
  sendInvoice = (invoice: InvoiceExtended): Observable<any> =>
    this.http.post('/office/invoices/' + invoice.id + '/send', invoice);
  deleteInvoice = (invoice: InvoiceExtended) =>
    this.http.delete('/office/invoices/' + invoice.id);
  downloadInvoicePDF = (id: number) =>
    this.http.get('/office/invoices/' + id + '/pdf').subscribe((response) => {
      if (response.status == 'success') {
        window.location = response.pdf;
      }
      return response;
    });
  getHostedLink = (id: number) =>
    this.http.get('/office/invoices/' + id + '/link');

  // Invoice Modals
  private currentInvoiceActionSource: BehaviorSubject<INVOICE_ACTIONS> =
    new BehaviorSubject<INVOICE_ACTIONS>(null);
  public currentInvoiceAction: Observable<any> =
    this.currentInvoiceActionSource.asObservable();
  setCurrentInvoiceAction = (action: INVOICE_ACTIONS) =>
    this.currentInvoiceActionSource.next(action);

  // Dashboard
  getBalance = (): Observable<any> =>
    this.http.get('/office/dashboard/balance');
  getEvents = (): Observable<any> =>
    this.http.get('/office/dashboard/event-list');

  // Invoices
  private allOpenInvoicesSource: BehaviorSubject<Invoice[]> =
    new BehaviorSubject<Invoice[]>(null);
  public allOpenInvoices: Observable<Invoice[]> =
    this.allOpenInvoicesSource.asObservable();
  getAllOpenInvoices = () =>
    this.getAllInvoices({ status: 'open' }).subscribe((invoices) => {
      this.allOpenInvoicesSource.next(invoices);
    });

  // Current Invoice
  private currentInvoiceSource: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public currentInvoice: Observable<any> =
    this.currentInvoiceSource.asObservable();

  setCurrentInvoice = (invoice: InvoiceExtended) =>
    this.currentInvoiceSource.next(invoice);
  removeCurrentInvoice = () => this.setCurrentInvoice(null);
  getCurrentInvoice = (id: number): any =>
    this.http.get('/office/invoices/' + id).subscribe(this.setCurrentInvoice);
  removeInvoiceItem = (id: number): Observable<any> =>
    this.http.delete('/office/invoice-items/' + id);

  private currentPayoutSource = new BehaviorSubject<PayoutDetails | null>(null);
  currentPayout = this.currentPayoutSource.asObservable();

  setCurrentPayout = (payout: PayoutDetails) =>
    this.currentPayoutSource.next(payout);

  getCurrentPayout = (id: string) => {
    // Load the payout if needed
    this.getPayout(id).subscribe({
      next: (payout) => this.setCurrentPayout(payout),
      error: (error) => console.error('Failed to load payout:', error),
    });

    // Return the subject's observable to maintain the stream
    return this.currentPayout;
  };

  clearCurrentPayout = () => this.setCurrentPayout(null);

  private unbilledClientAppointmentsSource: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public unbilledClientAppointment: Observable<any> =
    this.unbilledClientAppointmentsSource.asObservable();

  getUnbilledClientAppointments = (id: number) =>
    this.http
      .get('/office/appointments/' + id + '/unbilled')
      .subscribe((response) =>
        this.unbilledClientAppointmentsSource.next(response)
      );
  ignoreUnbilledItem = (
    client_id: number,
    id: number,
    isAppointment: boolean
  ): Observable<any> =>
    this.http.post('/office/appointments/' + client_id + '/ignore_unbilled', {
      id: id,
      is_appointment: isAppointment,
    });

  getClientDetails = (invoice: InvoiceExtended) =>
    this.http.get('/office/clients/' + invoice.id);
  updateClientDetails = (userid: number, invoiceClient: InvoiceClient) =>
    this.http.post('/office/clients/' + userid, { client: invoiceClient });

  //Taxrates
  getTaxrates = (): Observable<any> => this.http.get('/office/taxrates');
  saveTaxrate = (taxrate: any): Observable<any> =>
    this.http.post('/office/taxrates', taxrate);
  updateTaxrate = (taxrate: any): Observable<any> =>
    this.http.put('/office/taxrates', taxrate);

  // Invoice Settings
  getInvoiceSettings = (): Observable<any> =>
    this.http.get('/office/invoice-settings');
  updateInvoiceSettings = (invoiceSettings: any): Observable<any> =>
    this.http.put('/office/invoice-settings', invoiceSettings);

  // Balances
  private accountBalancesSource: BehaviorSubject<BalanceResponse> =
    new BehaviorSubject(null);
  public accountBalances: Observable<BalanceResponse> =
    this.accountBalancesSource.asObservable();

  loadAccountBalance = () => {
    this.http.get('/office/dashboard/balance_v2').subscribe((balances) => {
      this.accountBalancesSource.next(balances);
    });
  };

  // Payouts
  private showPaymentModalSource: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public showPaymentModal: Observable<any> =
    this.showPaymentModalSource.asObservable();
  requestPayout = (flag: boolean = true) =>
    this.showPaymentModalSource.next(flag);
  createPayout = (
    amount: number,
    currency: string,
    destination: string
  ): Observable<any> =>
    this.http.post('/office/dashboard/payout', {
      amount: amount,
      currency: currency,
      destination: destination,
    });

  // Amount
  getMonthlyEarnings = (): Observable<any> =>
    this.http.get('/office/dashboard/earnings');

  setStripeConnectClientSecretSource = (clientSecret: string | undefined) =>
    this.stripeConnectClientSecretSource.next(clientSecret);

  fetchStripeConnectClientSecret(): Observable<string | undefined> {
    return this.http
      .post('/office/account-session', {})
      .pipe(
        map((response) => {
          if (response.error) {
            // Handle client-side errors here
            console.log('An error occurred:', response.error);
            return null;
          }
          return response.client_secret;
        })
      )
      .pipe(
        tap((clientSecret) => {
          this.setStripeConnectClientSecretSource(clientSecret);
        })
      );
  }

  getAllInvoices(params: AllInvoicesParams): Observable<Invoice[]> {
    const httpParams = new HttpParams()
      .set('status', params.status || '')
      .set('query', params.query || '')
      .set('finalized_start', params.finalized_start || '')
      .set('finalized_end', params.finalized_end || '')
      .set('paid_start', params.paid_start || '')
      .set('paid_end', params.paid_end || '');

    return this.http
      .get<{ invoices: Invoice[] }>('/office/dashboard/invoice-list', {
        params: httpParams,
      })
      .pipe(map((response) => response.invoices));
  }

  getPaginatedInvoices(
    params: InvoiceTableParams
  ): Observable<PaginatedInvoices> {
    const httpParams = new HttpParams()
      .set('page', params.page.toString())
      .set('per_page', params.per_page.toString())
      .set('query', params.query || '')
      .set('status', params.status || '')
      .set('sort', params.sort || '')
      .set('direction', params.direction || '')
      .set('finalized_start', params.finalized_start || '')
      .set('finalized_end', params.finalized_end || '')
      .set('paid_start', params.paid_start || '')
      .set('paid_end', params.paid_end || '');

    return this.http.get<PaginatedInvoices>(
      '/office/dashboard/invoice-list-paginated',
      { params: httpParams }
    );
  }

  getPayoutsForExport(params: AllPayoutsParams): Observable<PayoutDetails[]> {
    const httpParams = new HttpParams()
      .set('status', params.status || '')
      .set('created_date_start', params.created_date_start || '')
      .set('created_date_end', params.created_date_end || '')
      .set('arrival_date_start', params.arrival_date_start || '')
      .set('arrival_date_end', params.arrival_date_end || '');

    return this.http
      .get<{ payouts: PayoutDetails[] }>('/office/payouts/payouts_for_export', {
        params: httpParams,
      })
      .pipe(map((response) => response.payouts));
  }

  getPaginatedPayouts(params: PayoutTableParams): Observable<PaginatedPayouts> {
    const httpParams = new HttpParams()
      .set('page', params.page.toString())
      .set('per_page', params.per_page.toString())
      .set('status', params.status || '')
      .set('sort', params.sort || '')
      .set('direction', params.direction || '')
      .set('arrival_date_start', params.arrival_date_start || '')
      .set('arrival_date_end', params.arrival_date_end || '')
      .set('created_date_start', params.created_date_start || '')
      .set('created_date_end', params.created_date_end || '');

    return this.http.get<PaginatedPayouts>('/office/payouts/index_paginated', {
      params: httpParams,
    });
  }

  getPayout(id: string): Observable<PayoutDetails> {
    return this.http.get<PayoutDetails>(`/office/payouts/${id}`);
  }
}

export interface AllInvoicesParams {
  status?: string;
  query?: string;
  finalized_start?: string;
  finalized_end?: string;
  paid_start?: string;
  paid_end?: string;
}

export interface AllPayoutsParams {
  status?: string;
  created_date_start?: string;
  created_date_end?: string;
  arrival_date_start?: string;
  arrival_date_end?: string;
}

export interface PayoutTableParams {
  page: number;
  per_page: number;
  sort?: string;
  direction?: 'asc' | 'desc';
  status?: string;
  arrival_date_start?: string;
  arrival_date_end?: string;
  created_date_start?: string;
  created_date_end?: string;
}

export interface InvoiceTableParams {
  page: number;
  per_page: number;
  sort?: string;
  direction?: 'asc' | 'desc';
  query?: string;
  status?: string;
  finalized_start?: string;
  finalized_end?: string;
  paid_start?: string;
  paid_end?: string;
}

export interface PaginatedPayouts {
  payouts: LeanPayout[];
  total_count: number;
  current_page: number;
  total_pages: number;
  per_page: number;
}

export interface PaginatedInvoices {
  invoices: Invoice[];
  total_count: number;
  current_page: number;
  total_pages: number;
  per_page: number;
}

export interface LeanPayout {
  id: number;
  stripe_id: string;
  status: string;
  created_at: string;
  arrival_date: string;
  currency: string;
  amount: number;
  reconciled_locally: boolean;
  automatic?: boolean;
  destination?: {
    bank_name: string;
    last4: string;
  };
}

export interface PayoutDetails extends LeanPayout {
  statement_descriptor?: string;
  description?: string;
  failure_code?: string;
  method?: string;
  trace_id_status?: 'pending' | 'supported' | 'unsupported';
  trace_id_value?: string;
  invoices?: Invoice[];
}

export interface CurrencyBalance {
  available: number;
  pending: number;
  unpaid: number;
  unpaid_count: number;
}

export interface BalanceResponse {
  [currency: string]: CurrencyBalance;
}
