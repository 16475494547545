import { Conversation } from './../../../entities/conversation.model';
import { Injectable } from '@angular/core';
import { ActionCableService, Cable, Channel } from 'angular2-actioncable';
import { environment } from 'src/environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { User } from '../../../entities/user.model';
import { GenericHttpService } from '../../../shared/services/Generic HTTP/generic-http.service';
import { MonitoringService } from '../../../shared/services/monitoring/monitoring.service';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  constructor(
    private socket: ActionCableService,
    private http: GenericHttpService
  ) {}

  private WS_URL: string = environment.ws;

  public cable: Cable;
  private userChannels: Channel[] = [];

  /**
   * Initialize websocket connection
   * to server
   * get's call after login
   */
  public setupConnection(token) {
    if (!this.isJwtValidFormat(token)) {
      MonitoringService.captureMessage(
        'Invalid JWT format for websocket:' + token
      );
    }
    this.cable = this.socket.cable(`${this.WS_URL}?token=${token}`);
  }

  isJwtValidFormat(token: string): boolean {
    const jwtPattern = /^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+$/;
    return jwtPattern.test(token);
  }

  /**
   *
   * Subscribe to User Channel
   * Used for Notifications and Incoming calls
   */
  public getUserChannel(user: User) {
    this.ensureConnection(user.websocket_token);
    if (!this.userChannels[user.id]) {
      this.userChannels[user.id] = this.cable.channel(
        'ApplicationCable::UserChannel',
        { id: user.id }
      );
    }
    return this.userChannels[user.id];
  }

  public createAnonymousVideoCall() {
    return this.http.post('/onlinesessions/public/anonymous', {});
  }

  public getVideoChannel(id: number) {
    return this.cable.channel('ApplicationCable::VideosessionChannel', {
      id: id,
    });
  }

  public ensureConnection(token) {
    if (!this.cable) {
      this.setupConnection(token);
    }
  }

  public disconnect() {
    if (this.cable) {
      this.cable.disconnect();
    }
  }
}
