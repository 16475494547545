import { User } from '../../entities/user.model';
import { environment } from '../../../environments/environment';
import { Profile } from '../../entities/profile.model';

// This helper is just to centralize navigation logic that we might want to improve in the future
// (e.g. if we want to change the URL structure or support more languages).
function findATherapistRoute(locale: string) {
  return locale === 'en' ? 'find-a-therapist' : 'finde-einen-therapeuten';
}

export function createProfileLink(therapist: User, currentUserLocale: string) {
  return [
    environment.baseUrl,
    currentUserLocale,
    'therapists',
    therapist.profile.slug,
  ].join('/');
}

export function createDirectoryLink(currentUserLocale: string) {
  return [
    environment.baseUrl,
    currentUserLocale,
    findATherapistRoute(currentUserLocale),
  ].join('/');
}

export function createApplyLink(currentUserLocale: string) {
  return [environment.baseUrl, currentUserLocale, 'apply'].join('/');
}

export function createNewWaitingRoomLink(therapistProfileSlug: string) {
  return [environment.baseUrl, 'online-session', therapistProfileSlug].join(
    '/'
  );
}

export function createEditProfileLink(
  currentUserLocale: string,
  currentProfile: Profile,
  page: string = ''
) {
  return [
    environment.baseUrl,
    currentUserLocale,
    'home',
    'profile',
    currentProfile.profile_completed || currentProfile.profile_approved
      ? 'edit'
      : 'create',
    page,
  ]
    .filter(Boolean)
    .join('/');
}
