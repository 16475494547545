import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { SharedService } from 'src/app/shared/services/shared.service';
import {
  FileSystemFileEntry,
  NgxFileDropEntry,
  NgxFileDropModule,
} from 'ngx-file-drop';
import { Profile } from 'src/app/entities/profile.model';
import { User } from 'src/app/entities/user.model';
import { PhotoUploaderCropperComponent } from './photo-uploader-crop/photo-uploader-cropper.component';
import { ProfileService } from 'src/app/components/profile/service/profile.service';
import { TranslateModule } from '@ngx-translate/core';
import { PhotoUploaderThumbnailComponent } from './photo-uploader-thumbnail/photo-uploader-thumbnail.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-photo-uploader',
    templateUrl: './photo-uploader.component.html',
    styleUrls: ['./photo-uploader.component.scss'],
    imports: [
        NgxFileDropModule,
        NgIf,
        PhotoUploaderCropperComponent,
        NgFor,
        PhotoUploaderThumbnailComponent,
        TranslateModule,
    ]
})
export class PhotoUploaderComponent implements OnInit {
  constructor(
    private profileService: ProfileService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {}

  @ViewChild('cropper', { static: false })
  public imageCropper: PhotoUploaderCropperComponent;

  @Input()
  public showModal: boolean;

  @Input()
  public currentUser: User;

  @Input()
  public dropZoneClass: string;

  @Input()
  public profile: Profile;

  public updater: EventEmitter<any> = new EventEmitter();

  @Output()
  closeModal: EventEmitter<any> = new EventEmitter();

  @Output()
  hideTourModal: EventEmitter<any> = new EventEmitter();

  public showPhotoSizeError: boolean;
  public previewImage: any;
  public loadingPhoto: boolean;
  public uploadPhoto: boolean;

  public photos: any[];

  /**
   * Validate photo size,
   * show in Image Cropper component.
   *
   * @param event Upload event
   *
   */
  onFileChanged(event) {
    this.showPhotoSizeError = false;
    if (event.target.files[0] && event.target.files[0].size / 1000000 > 2) {
      return (this.showPhotoSizeError = true);
    }
    this.previewImage = event;
    this.hideTourModal.emit(true);
  }

  /**
   * Insert image name to file, and call uploadPhotos()
   *
   * @param event
   */
  onPhotoEmitted(event) {
    if (event == false) {
      this.hideTourModal.emit(false);
      return (this.previewImage = null);
    } else if (event && event.name && event.image) {
      this.photos = [];
      event.image.blob.name = event.name;
      this.photos.push(event.image.blob);
      this.uploadPhotos();
      this.previewImage = null;
    }
  }

  /**
   * Save image from cropper
   *
   *
   */
  public saveImage(): void {
    this.imageCropper.saveImage();
    this.profileService.updateItem(this.profile, this.profile.id);
    this.hideTourModal.emit(false);
  }

  /**
   * Upload selected image to DB.
   *
   *
   */
  uploadPhotos() {
    this.photos.forEach((photo) => {
      let uploadData = new FormData();
      uploadData.append('photo', photo, photo.name);
      this.loadingPhoto = true;
      this.hideTourModal.emit(false);
      this.profileService.uploadPhotos(uploadData).subscribe((res) => {
        this.loadingPhoto = false;
        this.uploadPhoto = false;
        this.photos = [];
        this.profileService.updateItem(
          this.currentUser.profile,
          this.currentUser.id
        );
        this.closeModal.emit();
        this.sharedService.setCurrentUser(this.currentUser);
        this.updater.emit(true);
      });
    });
  }

  /**
   * Remove photo from DB.
   *
   * @param blob_id ID of image blob
   *
   */
  removePhoto(blob_id): void {
    this.profileService.removePhoto(blob_id).subscribe((res) => {
      let img = this.profile.images.find((img) => img.blob_id == blob_id);
      let index = this.profile.images.indexOf(img);
      this.profile.images.splice(index, 1);
      this.updater.emit(true);
    });
  }

  orderImages(data) {
    var count = 0;

    this.profile.images.splice(
      data.idx + data.dir,
      0,
      this.profile.images.splice(data.idx, 1)[0]
    );

    var order = this.profile.images.map((img) => {
      return { img: img.id, idx: count++ };
    });

    this.profileService.orderPhotos(order).subscribe((res) => {
      this.updater.emit(true);
    });
  }

  /**
   * Handle image drop and insert to
   * Image Cropper.
   *
   * @param event Upload event
   *
   */
  public dropped(files: NgxFileDropEntry[]) {
    this.showPhotoSizeError = false;
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file.size / 1024 / 1024 > 10)
            return (this.showPhotoSizeError = true);
          this.previewImage = { target: { files: [file] } };
          this.hideTourModal.emit(true);
        });
      }
    }
  }
}
