import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ImageCroppedEvent,
  ImageCropperComponent,
  ImageCropperModule,
} from 'ngx-image-cropper';

@Component({
    selector: 'app-photo-uploader-cropper',
    templateUrl: './photo-uploader-cropper.component.html',
    styleUrls: ['./photo-uploader-cropper.component.scss'],
    imports: [ImageCropperModule]
})
export class PhotoUploaderCropperComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  @ViewChild('cropper', { static: true })
  cropper: ImageCropperComponent;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  name: string;

  @Input()
  set file(file: any) {
    this.imageChangedEvent = file;
    if (file.target) {
      this.name = file.target.files[0].name;
    } else {
      this.name = file.name;
    }
  }

  @Output()
  photoEmitter = new EventEmitter();

  imageCropped(event: ImageCroppedEvent) {
    this.photoEmitter.emit({ image: event, name: this.name });
  }

  public saveImage() {
    this.cropper.crop();
  }

  public removeImage() {
    this.photoEmitter.emit(false);
  }
}
